<template>
  <!-- 禁止页面滚动收缩 @touchmove.prevent @mousewheel.prevent -->
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="content">
      <div class="title_box">
        <i class="el-icon-minus"></i>
        <span class="title">人才信用评价</span>
        <i class="el-icon-minus"></i>
      </div>
      <div class="search-input">
        <div class="input-icon"><i class="el-icon-search"></i></div>
        <input
          class="input-search"
          placeholder="请输入身份证号或者姓名查询"
          v-model="param.name"
          :autofocus="true"
        />
        <div class="input-btn" @click="searchPage">查询评价</div>
      </div>

      <div class="list_box">
        <el-card v-for="item in list" :key="item.id" class="box-card">
          <div @click="toDetail(item)">
            <div class="left">
              <el-row>
                <el-col :span="8">
                  <img
                    :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl"
                    :onerror="$global.srcImgUrl"
                    alt=""
                  />
                </el-col>
                <el-col :span="16">
                  <p class="textInfo title">{{ $processName(item.name) }}</p>
                  <p class="textInfo">
                    {{ item.sex === '1' ? '男' : '女' }} | {{ item.age }}岁 |
                    {{ item.nation ? item.nation : '汉' }}族
                  </p>
                  <p class="textInfo">{{ geTel(item.phone) }}</p>
                </el-col>
              </el-row>
            </div>
            <div class="right right_box">
              <div class="score">
                信用分:
                <!-- <span class="score_text">{{
                  Number(item.grade).toFixed(1)
                }}</span> -->
                <span
                  class="score_text"
                  v-if="item.grade >= 80"
                  :style="{ color: gradeColor[0], borderColor: gradeColor[0] }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  class="score_text"
                  v-if="item.grade < 80 && item.grade >= 60"
                  :style="{ color: gradeColor[1], borderColor: gradeColor[1] }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  class="score_text"
                  v-if="item.grade < 60 && item.grade >= 40"
                  :style="{ color: gradeColor[2], borderColor: gradeColor[2] }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
                <span
                  class="score_text"
                  v-if="item.grade < 40"
                  :style="{ color: gradeColor[3], borderColor: gradeColor[3] }"
                >
                  {{ Number(item.grade).toFixed(1) }}
                </span>
              </div>
              <div class="city">
                <i
                  class="iconfont icon-dingxiang"
                  style="margin-right: 20px; font-size: 18px"
                ></i>
                <span class="city-city"
                  >
                  <span v-if="item.province">{{ getProvinceForId(item.province) }} ·</span>
                  {{
                    item.city ? getCityForId(item.city) : ''
                  }}</span
                >
              </div>
            </div>
          </div>
        </el-card>

        <el-empty v-if="total == 0" description="空空如也"></el-empty>
        <div style="text-align: center; margin: 30px 0">
          <el-pagination
            :background="true"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog width="1050px" :visible.sync="outerVisible">
      <creditrating ref="creditrating"></creditrating>
    </el-dialog>

    <!-- 底部 -->
    <footerss class="footer"></footerss>
  </div>
</template>
<script>
import homes from '../../components/hometitle/hometitle.vue'
import footerss from '../../components/foot/foot.vue'
import { getDict } from '@/api/system/system'
import { getProvincial, getCity, getArea } from '@/api/index/index'
import creditrating from '../personalCenter/creditrating/creditrating.vue'
import { jobEvaluationSearch } from '@/api/evaluation/evaluation'
export default {
  components: {
    footerss,
    homes,
    creditrating,
  },
  data() {
    return {
      gradeColor: ['#67CF61', '#3B94F7', '#e6a23c', '#ff0000'],
      srcImgUrl: require("../../assets/home/people.png"),
      list: [],
      dict: [],
      orgType: [],
      jobEdu: [],
      cityList: [],
      showProvinceList: [], //省份列表
      showCityList: [], //城市列表
      jobExp: [],
      param: {
        type: '1',
        pageNo: 1,
        pageSize: 10,
        name: '',
      },
      total: 0,
      outerVisible: false,
    }
  },
  computed: {},
  created() {
    this._getDict()
    this._getProvincial()
    this._getCity()
    this._getArea()
    this.showProvinceList = JSON.parse(localStorage.getItem('provincialList'))
    this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    this.cityList = JSON.parse(localStorage.getItem('cityList'))
    if (this.$route.query.value) {
      this.param.name = this.$route.query.value
      this.page()
    } else {
      this.page()
    }
  },
  beforeUnmount() {},
  mounted() {},
  methods: {
    getCity(code) {
      var obj = this.cityList.find((e) => {
        e.CITY_CODE == code
      })
      if (obj) {
        return obj.CITY_NAME
      }
      return ''
    },

    // 回显省份
    getProvinceForId(id) {
      return this.showProvinceList.filter((i) => i.PROVINCE_CODE == id)[0]
        .SHORT_NAME
    },
    // 回显城市
    getCityForId(id) {
      return this.showCityList.filter((i) => i.CITY_CODE == id)[0].SHORT_NAME
    },
    searchPage(){
      this.param.pageNo = 1
      this.page()
    },
    page() {
      jobEvaluationSearch(this.param).then((res) => {
        if (res.success) {
          this.list = res.data.rows
          this.total = res.data.totalRows
        }
      })
    },
    toDetail(item) {
      // this.outerVisible = true
      // var that =this
      // setTimeout(function () {
      //   that.$refs.creditrating.loadData(item.idCard)
      // },200)
      this.$router.push({
	        name: "evaluationDetail",
	        query:{
	          // id: item.idCard
	          id: window.btoa(item.idCard),
	        }
        });
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth
      this.fullHeight = document.documentElement.clientHeight
      2
    },
    _getProvincial() {
      getProvincial().then((res) => {
        localStorage.setItem('provincialList', JSON.stringify(res.data))
      })
    },
    _getCity() {
      getCity().then((res) => {
        this.cityList = res.data
        localStorage.setItem('cityList', JSON.stringify(res.data))
      })
    },
    _getArea() {
      getArea().then((res) => {
        localStorage.setItem('areaList', JSON.stringify(res.data))
      })
    },
    _getDict() {
      getDict({}).then((res) => {
        if (res.code == 200 && res.message === '请求成功') {
          this.dict = res.data
          localStorage.setItem('dict', JSON.stringify(res.data))
          this.orgType = this.dict.filter(
            (i) => i.code == 'job_org_type'
          )[0].children
          this.jobEdu = this.dict.filter((i) => i.code == 'job_edu')[0].children
          this.jobExp = this.dict.filter((i) => i.code == 'job_exp')[0].children
        }
      })
    },

    getOrgType(id) {
      return this.orgType.filter((i) => i.code == id)[0].name
    },
    getPostEdu(id) {
      return this.jobEdu.filter((i) => i.code == id)[0].name
    },
    getPostExp(id) {
      return this.jobExp.filter((i) => i.code == id)[0].name
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return tel ? tel.replace(reg, '$1****$2') : ''
    },
    handleCurrentChange(val) {
      this.param.pageNo = val
      this.page()
    },
  },
}
</script>
<style scoped lang="scss">
.content {
  min-height: 650px;
  width: 100%;
  // height: 101%;
  background-size: cover;
  // height: 100%;
  margin: 0 auto;
  background: url('../../assets/course/result_bg.png');
  padding-top: 200px;
  background-repeat: no-repeat;
}

.title_box {
  text-align: center;
  margin-bottom: 50px;
  font-size: 38px;
  color: #029aff;
  .title {
    margin: 0 40px 0;
    padding-bottom: 10px;
  }
  // .title:hover {
  //   text-decoration: underline;
  // }
}

.search-input:hover {
  border-style: solid;
  border-color: #029aff;
  border-width: 1px;
  height: 70px;
}
.search-input {
  width: 1000px;
  height: 72px;
  margin: 0 auto;
  display: flex;

  .input-icon {
    line-height: 72px;
    font-size: 30px;
    width: 60px;
    background-color: rgba(237, 228, 217, 0.2);
    text-align: center;

    i {
      color: #fff;
      opacity: 1;
    }
  }

  .input-search {
    width: 790px;
    background-color: rgba(237, 228, 217, 0.2);
    border: none;
    border-radius: 0;
    color: #fff;
    font-size: 18px;
  }

  .input-search:focus {
    outline: none;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    border: none;
    color: #fff;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }

  .input-btn {
    width: 150px;
    line-height: 72px;
    background-color: #029aff;
    color: #fff;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }
}

.list_box {
  margin-top: 50px;
  overflow: hidden;
  .box-card {
    margin-left: 15%;
    margin-bottom: 20px;
    width: 70%;
    margin-bottom: 10px;
    .left {
      width: 400px;
      float: left;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }

      .textInfo {
        margin-top: 0;
      }
      .title {
        font-weight: 800;
      }
    }
    .right {
      float: right;
    }

    .right_box {
      width: 200px;
      .score {
        margin-bottom: 50px;
        margin-left: 50px;
        .score_text {
          border-style: solid;
          border-color: #f22;
          color: #f22;
          border-width: 1px;
          padding: 0 10px 0;
          border-radius: 15px 15px 15px 1px;
          font-family: 'buer';
        }
        // .score_text:hover {
        //   color: #fff;
        //   background-color: #f22;
        // }
      }
    }

    .city {
      font-size: 14px;
      color: #1592ea;
      padding-left: 60px;
      .city-city {
        color: #999999;
      }
    }
  }

  .box-card:hover {
    background-color: #eef;
  }
}
</style>
